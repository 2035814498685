@tailwind base;
@tailwind components;
@tailwind utilities;



#sidebar a.active {
  background-color: rgba(164, 162, 162, 0.219);
}
.input-error {
  color: red;
  font-size: 13px;
}
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Scrollbar Thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #88888b; /* Color of the thumb */
  border-radius: 10px;
  /* Rounded corners of the thumb */
}

/* Scrollbar Track (the non-draggable part) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 4px; /* Rounded corners of the track */
}
